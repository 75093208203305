import React from 'react'
import styled from 'styled-components'

import { Header } from '../atoms/Typography/Header'
import { Text } from '../atoms/Typography/Text'
import SEO from '../components/seo'
import Main from '../layouts/main'
import { SectionWrapper } from '../molecules/SectionWrapper/SectionWrapper'

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CookiesPolicy = () => (
  <Main>
    <SEO title='Poradnia Rehabilitacyjna Azory' />
    <SectionWrapper paddingTop paddingBottom>
      <FlexWrapper>
        <Header variant='heading2'>Polityka cookies</Header>
        <Text variant='medium'>
          1. Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach
          cookies.
          <br />
          2. Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które
          przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron
          internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas
          przechowywania ich na urządzeniu końcowym oraz unikalny numer.
          <br />
          3. Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich
          dostęp jest operator Serwisu NZOZ Poradnia Rehabilitacyjna &quot;AZORY&quot; spółka z.o.o. z siedzibą pod adresem:
          ul. Nałkowskiego 1, 31-308 Kraków.
          <br />
          4. Pliki cookies wykorzystywane są w celu tworzenia statystyk, które pomagają zrozumieć, w jaki sposób
          Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości.
          <br />
          5. W ramach Serwisu stosowane są „stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika
          przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.
          <br />
          6. W ramach Serwisu stosowane są następujące rodzaje plików cookies: „wydajnościowe” pliki cookies,
          umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu.
          <br />
          7. W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa)
          domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą
          dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w
          szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki
          internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe
          informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania
          (przeglądarki internetowej).
          <br />
          8. Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
          funkcjonalności dostępne na stronach internetowych Serwisu.
          <br />
          9. Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również
          przez współpracujących z operatorem Serwisu reklamodawców oraz partnerów.
        </Text>
      </FlexWrapper>
    </SectionWrapper>
  </Main>
)

export default CookiesPolicy
